import { useState, useEffect } from 'react';

export const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState('up');
  const threshold = 10;
  let previousScroll = 0;

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      
      if (Math.abs(currentScroll - previousScroll) < threshold) {
        return;
      }

      if (currentScroll > previousScroll) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }
      
      previousScroll = currentScroll;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return scrollDirection;
}; 