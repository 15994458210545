import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import AppTitle from './AppTitle';
import ThemeToggle from './ThemeToggle';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import UserProfileMenu from './UserProfileMenu';
import { useScrollDirection } from '../hooks/useScrollDirection';

const CollapsibleHeader = ({ user, isDarkMode, toggleTheme }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const scrollDirection = useScrollDirection();
  
  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: { xs: 'flex', sm: 'none' },
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 16px',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: 'background.default',
        transition: 'transform 0.3s ease-in-out',
        transform: scrollDirection === 'down' ? 'translateY(-100%)' : 'translateY(0)',
        zIndex: 1002,
      }}
    >
      <AppTitle />
      <Box sx={{ display: 'flex', gap: 0.5 }}>
        <ThemeToggle isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
        {user && (
          <>
            <IconButton
              onClick={handleProfileClick}
              sx={{ color: 'text.primary', padding: '4px' }}
            >
              <Avatar
                src={user?.photoURL}
                alt={user?.displayName}
                sx={{ width: 32, height: 32 }}
              />
            </IconButton>
            <UserProfileMenu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              user={user}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default CollapsibleHeader; 