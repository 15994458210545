import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Stack
} from '@mui/material';
import { stripePromise } from '../config/stripe';

const SubscriptionModal = ({ open, onClose, userId, currentConversation, isOfferPeriod }) => {
  const [countdown, setCountdown] = useState('');

  const monthlyOfferPrice = '$4.99';
  const annualOfferPrice = '$2.99';

  useEffect(() => {
    let intervalId;
    if (isOfferPeriod) {
      const endOfferDate = new Date('2025-01-01T23:59:59');
      intervalId = setInterval(() => {
        const now = new Date();
        const diff = endOfferDate - now;

        if (diff <= 0) {
          clearInterval(intervalId);
          setCountdown('Offer expired');
        } else {
          const days = Math.floor(diff / (1000 * 60 * 60 * 24));
          const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((diff % (1000 * 60)) / 1000);
          setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isOfferPeriod]);

  const handleSubscribe = async () => {
    if (currentConversation && currentConversation.length > 0) {
      localStorage.setItem('pendingConversation', JSON.stringify(currentConversation));
    }

    const stripe = await stripePromise;

    // Determine Price ID based on environment and offer period
    let monthlyPriceId;
    if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
      monthlyPriceId = isOfferPeriod ? 'price_1QZ2F201ij5HqBYYY8yZ2LHR' : 'price_1QHcex01ij5HqBYYpJu27wth';
    } else {
      monthlyPriceId = isOfferPeriod ? 'price_1QZ4Qc01ij5HqBYYUfgqlA3g' : 'price_1QQ0Uj01ij5HqBYYYD4UXQIp';
    }

    const response = await fetch('/api/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, isOfferPeriod, monthlyPriceId }),
    });

    const session = await response.json();

    const result = await stripe.redirectToCheckout({
      sessionId: session.sessionId,
    });

    if (result.error) {
      console.error(result.error);
      localStorage.removeItem('pendingConversation');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 1
        }
      }}
    >
      <DialogTitle sx={{ pb: 1 }}> 🔥 Limited Time Offer: Unlock Premium for Less! 🔥</DialogTitle>
      <DialogContent>
        <Box sx={{ p: 1 }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 500, mb: 2 }}>
            You get:
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            • Unlimited AI conversations
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            • Exclusive access to exciting new features
          </Typography>
          <Stack spacing={1} sx={{ mb: 2 }}>
            {isOfferPeriod ? (
              <>
                <Typography
                  variant="body1"
                  sx={{
                    textDecoration: 'line-through',
                    color: 'text.secondary',
                  }}
                >
                  $19.99/month
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: 'accent.main',
                    fontWeight: 'medium',
                  }}
                >
                  {monthlyOfferPrice}/month *Save 75%!*
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: 'accent.main',
                    fontWeight: 'medium',
                    fontSize: '1.1rem',
                    display: 'block',
                    mt: 1,
                    mb: 1
                  }}
                >
                  OR unlock even greater savings with annual billing.
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: 'accent.main',
                    fontWeight: 'medium',
                    fontSize: '1.1rem',
                    display: 'block',
                    mt: 1,
                    mb: 1
                  }}
                >
                  Limited time offer when billed annually: {annualOfferPrice}/month *Save 85%!*
                </Typography>
                {countdown && (
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Offer ends in: {countdown}
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Typography
                  variant="body1"
                  sx={{
                    textDecoration: 'line-through',
                    color: 'text.secondary',
                  }}
                >
                  $19.99/month
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: 'accent.main',
                    fontWeight: 'medium',
                  }}
                >
                  $9.99/month
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: 'accent.main',
                    fontWeight: 'medium'
                  }}
                >
                  Limited time offer when billed annually
                </Typography>
              </>
            )}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2, pt: 0 }}>
        <Button onClick={onClose} sx={{ color: 'text.secondary' }}>
          Cancel
        </Button>
        <Button
          onClick={handleSubscribe}
          variant="contained"
          sx={{
            bgcolor: 'accent.main',
            '&:hover': {
              bgcolor: 'accent.dark'
            }
          }}
        >
          Subscribe Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionModal;
