import React, { useState, useEffect, useRef } from "react";
import { auth } from "../firebase-config";
import BotImage from "./BotImage.svg";
import UserImage from "./UserImage.svg";
import styles from "./LandingPage.module.css";
import {
    Box,
    TextField,
    Typography,
    Avatar,
    IconButton,
    Paper,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
// REMOVE AXIOS IMPORT - WE WILL USE FIRESTORE SDK NOW
// import axios from "axios"; 
import TypingIndicator from "./TypingIndicator";
import { useSearchParams } from "react-router-dom";
// IMPORT FIRESTORE FUNCTIONS
import {
    collection,
    doc,
    setDoc,
    addDoc,
    serverTimestamp,
    getDoc,
    arrayUnion,
    query,
    orderBy,
    increment,
    updateDoc,
} from "firebase/firestore";
import { db } from "../firebase-config";
import UserAvatar from "./UserAvatar";
import { alpha, useTheme } from '@mui/material/styles';
import SubscriptionModal from './SubscriptionModal';
import { onAuthStateChanged } from 'firebase/auth';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Snackbar, Alert } from '@mui/material';
import { useLocation } from 'react-router-dom';

export const ChatPage = ({ user, isDarkMode }) => {
    console.log('User in ChatPage:', user);
    const searchParams = useSearchParams();
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [firstMsgFromUser] = useState(() => {
        const params = new URLSearchParams(window.location.search);
        const firstMsg = params.get("first");
        
        // If we have a pending conversation from Stripe checkout, don't set initial message
        const pendingConversation = localStorage.getItem('pendingConversation');
        if (pendingConversation) {
            return [];
        }
        
        // Only create initial message if we have first parameter
        return firstMsg ? [{
            content: firstMsg,
            role: "user",
        }] : [];
    });
    const [messages, setMessages] = useState(firstMsgFromUser);
    const [input, setInput] = useState("");
    const [userLocation, setUserLocation] = useState({ latitude: null, longitude: null });
    const [text, setDefaultState] = useState("");
    const [botTyping, setBotTyping] = useState(false);
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showSuccess, setShowSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setUserLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            });
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    const messageEndRef = useRef(null);

    const handleInputChange = (event) => {
        setBtnDisabled(!event.target.value);
        setInput(event.target.value);
        setDefaultState(event.target.value);
    };

    const handleMessageSubmit = async (e) => {
        e.preventDefault();
        if (input.trim() === "") return;

        try {
            // Check subscription status and message limit
            const userRef = doc(db, "Users", auth.currentUser.uid);
            const userDoc = await getDoc(userRef);
            const userData = userDoc.data();

            if (userData.subscriptionStatus === "free" && userData.freeMessagesRemaining <= 0) {
                setShowSubscriptionModal(true);
                
                // Schedule upgrade email
                try {
                    await fetch('/api/schedule-upgrade-email', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userId: auth.currentUser.uid }),
                    });
                } catch (error) {
                    console.error('Error scheduling upgrade email:', error);
                }
                
                return;
            }

            const userMessage = { role: "user", content: input };
            setMessages((prevMessages) => [...prevMessages, userMessage]);
            setInput("");
            setDefaultState("");
            setBtnDisabled(true);
            setBotTyping(true);

            // Store user message and update interaction count
            await sendMessageToFirestore(input, "user");
            
            // Decrease free messages count if user is on free plan
            if (userData.subscriptionStatus === "free") {
                await updateDoc(userRef, {
                    freeMessagesRemaining: increment(-1),
                    interactionCount: increment(1)
                });
                console.log("Successfully updated message count");
                
                // Verify the update
                const updatedDoc = await getDoc(userRef);
                console.log("Updated user data:", updatedDoc.data());
            }

            // Get bot response
            const chatbotResponse = await sendMessageToBackend(input);
            setBotTyping(false);
            
            if (chatbotResponse) {
                const botMessage = { role: "system", content: chatbotResponse };
                setMessages((prevMessages) => [...prevMessages, botMessage]);
                await sendMessageToFirestore(chatbotResponse, "system");
            }

        } catch (error) {
            console.error("Error handling message:", error);
            setBotTyping(false);
            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    role: "system",
                    content: "Sorry, there was an error processing your request. Please try again.",
                },
            ]);
        }
    };

    // MODIFY sendMessageToFirestore FUNCTION
    const sendMessageToFirestore = async (message, role) => {
        if (!auth.currentUser) {
            console.error("No authenticated user found");
            return;
        }

        const userId = auth.currentUser.uid;
        const sessionDate = new Date().toISOString().split("T")[0];
        const sessionRef = doc(db, "Users", userId, "sessions", sessionDate);
        
        try {
            const newMessage = {
                text: message,
                from: role === "user" ? "user" : "system",
                timestamp: new Date().toISOString() // Use a string timestamp instead
            };
            await setDoc(sessionRef, {
                date: serverTimestamp(),
                messages: arrayUnion(newMessage)
            }, { merge: true });
    
            console.log("Message stored to Firestore successfully!");
        } catch (error) {
            console.error("Error storing message to Firestore:", error);
            // Handle the error, e.g., by showing an error message to the user
            throw error; // Re-throw to be caught in handleMessageSubmit
        }
    };

    const sendMessageToBackend = async (message) => {
        if (!auth.currentUser) {
            console.error("No authenticated user found");
            throw new Error("No authenticated user found");
        }

        const userId = auth.currentUser.uid;
        const emotionData = localStorage.getItem('emotionButtonData');
        const isEmotionButtonMessage = emotionData ? JSON.parse(emotionData).isEmotionButton : false;

        try {
            const response = await fetch('/api/chatbot', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await auth.currentUser.getIdToken()}`
                },
                body: JSON.stringify({
                    message,
                    latitude: userLocation.latitude,
                    longitude: userLocation.longitude,
                    conversationHistory: messages,
                    isEmotionButtonMessage,
                }),
            });

            // Clear the emotion data after sending
            localStorage.removeItem('emotionButtonData');

            if (!response.ok) {
                const errorText = await response.text();
                console.error("Backend error:", errorText);
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            return data.message;
        } catch (error) {
            console.error("Error in sendMessageToBackend:", error);
            throw error;
        }
    };

    const handleFirstMsg = async () => {
        // Only proceed if there's no existing messages
        if (messages.length > 1 || messages[0].processed) return;
        setBotTyping(true);
        try {
            const firstMsg = { ...messages[0], processed: true };
            setMessages([firstMsg]);
            // Store the first user message in Firestore
            console.log("Handling first message:", firstMsgFromUser[0].content);
            await sendMessageToFirestore(firstMsgFromUser[0].content, "user");

            console.log("Sending first message to backend");
            const chatbotResponse = await sendMessageToBackend(firstMsg.content);
            console.log("Received response from backend:", chatbotResponse);
            setBotTyping(false);
            const botMessage = { role: "system", content: chatbotResponse };
            setMessages((prevMessages) => [...prevMessages, botMessage]);

            // Store bot message in Firestore
            console.log("Storing bot response in Firestore");
            await sendMessageToFirestore(chatbotResponse, "system");
        } catch (error) {
            // Handle potential errors here
            console.error(
                "Error processing first message:",
                error
            );
            setBotTyping(false);
            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    role: "system",
                    content:
                        "Sorry, there was an error. Please try again.",
                },
            ]);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleMessageSubmit(event);
        }
    };

    useEffect(() => {
        const userId = auth.currentUser?.uid;
        if (userId) {
            fetchMessages();
        }
    }, [auth.currentUser]);
    
        const fetchMessages = async () => {
            try {
                const sessionDate = new Date().toISOString().split("T")[0];
                const userId = auth.currentUser.uid;
                const sessionRef = doc(db, "Users", userId, "sessions", sessionDate);
                const sessionSnap = await getDoc(sessionRef);

                if (sessionSnap.exists()) {
                    const sessionData = sessionSnap.data();
                    if (sessionData.messages && sessionData.messages.length > 0) {
                        const fetchedMessages = sessionData.messages.map(msg => ({
                            role: msg.from === "user" ? "user" : "system",
                            content: msg.text,
                            timestamp: msg.timestamp,
                            processed: true
                        }));
                        setMessages(fetchedMessages);
                    } else {
                        // Keep the firstMsgFromUser if no messages in Firestore
                        setMessages([{ ...firstMsgFromUser[0], processed: false }]);
                      }
                    } else {
                      // Keep the firstMsgFromUser if no session in Firestore
                      setMessages([{ ...firstMsgFromUser[0], processed: false }]);
                    }
                    console.log("Fetched messages:", messages);
                  } catch (error) {
                    console.error("Error fetching messages:", error);
                    setMessages(firstMsgFromUser);
                  }
                };

    useEffect(() => {
        console.log("Current messages:", messages);
        if (messages.length === 1 && messages[0].role === "user" && !messages[0].processed) {
            console.log("Calling handleFirstMsg");
            handleFirstMsg();
        }
        getUserLocation();
}, [messages]); 

    useEffect(() => {
        if (messageEndRef.current) {
            messageEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    useEffect(() => {
        const isEmotionButtonMessage = localStorage.getItem('isEmotionButtonMessage') === 'true';
        if (isEmotionButtonMessage) {
            console.log("Emotion button message detected on ChatPage mount");
            // You can set a state here if needed
        }
    }, []);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLoading(false);
            } else {
                // Store success parameters if they exist
                const params = new URLSearchParams(window.location.search);
                const hasSuccess = params.get('success') === 'true';
                const sessionId = params.get('session_id');
                
                if (hasSuccess && sessionId) {
                    // Store these in localStorage instead of sessionStorage
                    localStorage.setItem('stripeSuccess', 'true');
                    localStorage.setItem('stripeSessionId', sessionId);
                }
                // Don't navigate immediately, wait a bit for auth to restore
                setTimeout(() => {
                    if (!auth.currentUser) {
                        navigate('/', { state: { redirectedFromPayment: true } });
                    }
                }, 2000); // Give Firebase auth 2 seconds to restore
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const success = params.get('success') === 'true';

        if (success) {
            const emotionData = localStorage.getItem('emotionButtonData');
            const savedConversation = localStorage.getItem('pendingConversation');
            
            if (emotionData) {
                const { message } = JSON.parse(emotionData);
                setInput(message);
                // Will trigger message send through the input effect
            } else if (savedConversation) {
                setMessages(JSON.parse(savedConversation));
            }
            
            localStorage.removeItem('pendingConversation');
            localStorage.removeItem('emotionButtonData');
            setShowSuccess(true);
            
            const newUrl = `${window.location.pathname}${window.location.hash}`;
            window.history.replaceState({}, '', newUrl);
        }
    }, [location]);

    // Add this effect to store conversation updates
    useEffect(() => {
        if (messages.length > 0) {
            localStorage.setItem('currentConversation', JSON.stringify(messages));
        }
    }, [messages]);

    const isWithinOfferPeriod = () => {
        const now = new Date();
        const startOffer = new Date('2024-12-23T00:00:00');
        const endOffer = new Date('2025-01-01T23:59:59');
        return now >= startOffer && now <= endOffer;
    };

    if (isLoading) {
        return (
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100vh',
                bgcolor: 'background.default'
            }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div className={`${styles.chatpage} chat-page-container`}>
            <Box sx={{ 
                flexGrow: 1, 
                overflow: "auto", 
                p: 5,
                mb: 2, // Add margin at the bottom
                '&::-webkit-scrollbar': {
                    width: '6px',
                },
                '&::-webkit-scrollbar-track': {
                    background: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    borderRadius: '3px',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                    },
                },
            }}>
                {messages.map((message, index) => (
                    <Message key={index} message={message} isDarkMode={isDarkMode} />
                ))}
                {botTyping && <TypingIndicator />}
                <div ref={messageEndRef} />
            </Box>

            <div className={styles.chatbottom}>
                <Box className={styles.messagetextareaframe} sx={{
                    backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(245, 209, 199, 0.35)',
                }}>
                    <TextField
                        className={styles.typeYourMessage}
                        id="text-field"
                        placeholder="Type your message..."
                        color="primary"
                        variant="standard"
                        multiline
                        InputProps={{ disableUnderline: true }}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        value={text}
                    />
                    <IconButton
                        color="primary"
                        disabled={btnDisabled}
                        onClick={handleMessageSubmit}
                        style={{ color: btnDisabled ? "#3b3b3b" : "inherit" }}
                    >
                        <SendIcon />
                    </IconButton>
                </Box>
            </div>
            <SubscriptionModal 
                open={showSubscriptionModal}
                onClose={() => setShowSubscriptionModal(false)}
                userId={auth.currentUser.uid}
                currentConversation={messages}
                isOfferPeriod={isWithinOfferPeriod()}
            />
            <Snackbar
                open={showSuccess}
                autoHideDuration={6000}
                onClose={() => setShowSuccess(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    onClose={() => setShowSuccess(false)} 
                    severity="success" 
                    sx={{ width: '100%' }}
                >
                    Successfully upgraded to premium! Enjoy unlimited messages.
                </Alert>
            </Snackbar>
        </div>
    );
};

const Message = ({ message, isDarkMode }) => {
  console.log("Rendering message:", message.content);
  const theme = useTheme();
  const isBot = message.role === "system";

  const renderContent = (content) => {
    if (!content) return null;
    const parts = content.split(/(\[.*?\]\(.*?\))/g);
    return parts.map((part, index) => {
      const match = part.match(/\[(.*?)\]\((.*?)\)/);
      if (match) {
        return (
          <a key={index} href={match[2]} target="_blank" rel="noopener noreferrer">
            {match[1]}
          </a>
        );
      }
      return <React.Fragment key={index}>{part}</React.Fragment>;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isBot ? "flex-start" : "flex-end",
        alignItems: 'flex-start', // Add this line
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isBot ? "row" : "row-reverse",
          alignItems: 'flex-start', // Add this line
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: '50px', // Add this line
          }}
        >
          {isBot ? (
            <Avatar
              sx={{
                bgcolor: "primary.main",
              }}
              alt="BotImage"
              src={BotImage}
            />
          ) : (
            <UserAvatar />
          )}
          <Typography variant="body2">
            {isBot ? "Buddy" : "You"}
          </Typography>
        </Box>
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            ml: isBot ? 1 : 0,
            mr: isBot ? 0 : 1,
            backgroundColor: isBot
              ? (isDarkMode ? "primary.dark" : alpha(theme.palette.primary.light, 0.5))
              : (isDarkMode ? "secondary.dark" : "secondary.light"),
            borderRadius: isBot
              ? "20px 20px 20px 5px"
              : "20px 20px 5px 20px",
            maxWidth: '80%', // Add this line
            wordBreak: 'break-word', // Add this line
          }}
        >
          <Typography variant="body1" sx={{ overflowWrap: "anywhere", whiteSpace: "pre-wrap" }}>
            {renderContent(message.content)}
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
};

export default ChatPage;